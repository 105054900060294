import React, { useRef, useState } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';


function Privacy() {
  return (
    <div className="Privacy">
      <nav>
          <Link to="/"><img src="../bellamya-logo.svg" alt="Bellamya Logo"></img></Link>
          <Link to="/"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><title>arrow left</title><g class="nc-icon-wrapper" fill="#000000"><path d="M11,5H3.414L5.707,2.707A1,1,0,0,0,4.293,1.293l-4,4a1,1,0,0,0,0,1.416l4,4A1,1,0,1,0,5.707,9.293L3.414,7H11a1,1,0,0,0,0-2Z" fill="#000000"/></g></svg></Link>
      </nav>
      <section></section>
      <section>
      <h2>Datenschutzerklärung</h2>
      <h2>Wer wir sind</h2>
      <p>Die Adresse unserer Website ist: http://bellamya.de.</p>
      <h2>Welche personenbezogenen Daten wir sammeln und warum wir sie sammeln</h2>
      <p>Aus dem Kontaktformular werden beim Absenden alle Daten die in den Feldern Ihr Name, Ihre E-Mail, Ihre Anfrage, und Ihre Telefonnummer über den Emailjs-Dienst an info@bellamya.de weitergeleitet damit wir sie Kontaktieren können. Die Datenschutzerklärung des EmailJS-Dienstes findest du hier: https://www.emailjs.com/legal/privacy-policy/.</p>
      <h2>Medien</h2>
      <p>Wenn du ein registrierter Benutzer bist und Fotos auf diese Website lädst, solltest du vermeiden, Fotos mit einem EXIF-GPS-Standort hochzuladen. Besucher dieser Website könnten Fotos, die auf dieser Website gespeichert sind, herunterladen und deren Standort-Informationen extrahieren.</p>
      <h2>Kontaktformulare</h2>
      <h2>Cookies</h2>
      <h2>Eingebettete Inhalte von anderen Websites</h2>
      <p>Beiträge auf dieser Website können eingebettete Inhalte beinhalten (z.&nbsp;B. Videos, Bilder, Beiträge etc.). Eingebettete Inhalte von anderen Websites verhalten sich exakt so, als ob der Besucher die andere Website besucht hätte.</p>
      <p>Diese Websites können Daten über dich sammeln, Cookies benutzen, zusätzliche Tracking-Dienste von Dritten einbetten und deine Interaktion mit diesem eingebetteten Inhalt aufzeichnen, inklusive deiner Interaktion mit dem eingebetteten Inhalt, falls du ein Konto hast und auf dieser Website angemeldet bist.</p>
      <h2>Wie lange wir deine Daten speichern</h2>
      <h2>Analysedienste</h2>
      <p>Wenn du einen Kommentar schreibst, wird dieser inklusive Metadaten zeitlich unbegrenzt gespeichert. Auf diese Art können wir Folgekommentare automatisch erkennen und freigeben, anstelle sie in einer Moderations-Warteschlange festzuhalten.</p>
      <p>Für Benutzer, die sich auf unserer Website registrieren, speichern wir zusätzlich die persönlichen Informationen, die sie in ihren Benutzerprofilen angeben. Alle Benutzer können jederzeit ihre persönlichen Informationen einsehen, verändern oder löschen (der Benutzername kann nicht verändert werden). Administratoren der Website können diese Informationen ebenfalls einsehen und verändern.</p>
      <h2>Welche Rechte du an deinen Daten hast</h2>
      <p>Wenn du ein Konto auf dieser Website besitzt oder Kommentare geschrieben hast, kannst du einen Export deiner personenbezogenen Daten bei uns anfordern, inklusive aller Daten, die du uns mitgeteilt hast. Darüber hinaus kannst du die Löschung aller personenbezogenen Daten, die wir von dir gespeichert haben, anfordern. Dies umfasst nicht die Daten, die wir aufgrund administrativer, rechtlicher oder sicherheitsrelevanter Notwendigkeiten aufbewahren müssen.</p>
      <h2>Wohin wir deine Daten senden</h2>
      <p>Besucher-Kommentare könnten von einem automatisierten Dienst zur Spam-Erkennung untersucht werden.</p>
      </section>
    </div>
  );
}

export default Privacy;
