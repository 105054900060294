import './App.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import  {ReactComponent as ThanksImg} from './assets/gfx/thanks.svg';
import  {ReactComponent as SeperatorImg} from './assets/gfx/bella-top.svg';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

function App() {
  let [formSent, setFormSent] = useState(false);

  function scrollInView() {
    document.getElementById('sctn-form').scrollIntoView();
  }

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('default_service', process.env.REACT_APP_TEMPLATE, form.current, process.env.REACT_APP_PUBLIC_KEY)
      .then((result) => {
        setFormSent(true);
        console.log(result.text);
        formSent = true;
      }, (error) => {
        console.log(error.text);
      });
    };

    return (
      <div className="App">
        <nav>
          <img src="bellamya-logo.svg" alt="Bellamya Logo"></img>
          <button onClick={scrollInView}>Termin Vereinbaren</button>
        </nav>
        <header className="App-header">
          <h2>Willkommen bei</h2>
          <h3>Bellamya<br/><span>Sanfte Umfangreduktion</span></h3>
          <p>Stralsunderstr. 1, 71065 Sindelfingen</p>
        </header>
        <section className="flex advantages">
          <div className="col-1-3">
            <img className='advantage-img' src={require('./assets/gfx/advantages1.jpg')} alt="Sanfte behandlung"></img>
            <h4>Keine strenge Diät</h4>
          </div>
          <div className="col-1-3">
            <img className='advantage-img' src={require('./assets/gfx/advantages2.jpg')} alt="Sanfte behandlung"></img>
            <h4>Keine Überanstrengung</h4>
          </div>
          <div className="col-1-3">
            <img className='advantage-img' src={require('./assets/gfx/advantages3.jpg')} alt="Sanfte behandlung"></img>
            <h4>Nachhaltige Ergebnisse</h4>
          </div>
        </section>
        <SeperatorImg/>
        <div className='swiper'>
        <Swiper
        className='swiper'
          spaceBetween={50}
          slidesPerView={'auto'}
          centeredSlides={'true'}
          navigation
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
          modules={[Pagination, Navigation, Autoplay]}
          effect={'coverflow'}
        >
          <SwiperSlide>
            <img src={require('./assets/gfx/slide2.jpg')} alt="Sanfte Umfangreduktion behandlung"></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src={require('./assets/gfx/slide3.jpg')} alt="Sanfte Umfangreduktion behandlung"></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src={require('./assets/gfx/slide4.jpeg')} alt="Vorher Nacher"></img>
          </SwiperSlide>
        </Swiper>
        <h3 className='swiper-headline'>Vorher / Nacher</h3>
        <Swiper
        className='swiper'
          spaceBetween={50}
          slidesPerView={'auto'}
          centeredSlides={'true'}
          navigation
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
          modules={[Pagination, Navigation, Autoplay]}
          effect={'coverflow'}
        >
          <SwiperSlide>
            <img src={require('./assets/gfx/beforeafter1.jpg')} alt="Vorher Nacher Bild 1"></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src={require('./assets/gfx/beforeafter2.jpg')} alt="Vorher Nacher Bild 2"></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src={require('./assets/gfx/beforeafter3.jpg')} alt="Vorher Nacher Bild 3"></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src={require('./assets/gfx/beforeafter4.jpg')} alt="Vorher Nacher Bild 4"></img>
          </SwiperSlide>
        </Swiper>
        </div>
        <section className="sctn-form" id="sctn-form">
        { formSent ?
          <div className="flex form-sent">
            <ThanksImg/>
            <h1>Vielen Dank!<br/>Wir haben ihre Anfrage erhalten und melden uns schnellstmöglich bei Ihnen</h1>
          </div> :
          <form className="form" id="form"  ref={form} onSubmit={sendEmail}>
            <div>
              <label for="name">Ihr Name*</label>
              <input
                type="text"
                name="name"
                required
                className="name"
                tabIndex="1"
                placeholder='Ihr Name'
              />
            </div>
            <div>
              <label for="email">Ihre E-mail*</label>
              <input
                type="email"
                required
                name="email"
                id="email"
                className="email"
                placeholder="Ihre E-Mail"
                tabIndex="3"
              />
            </div>
            <div>
              <label for="tel">Ihre Telefonnummer</label>
              <input
                type="tel"
                name="tel"
                id="tel"
                className="tel"
                placeholder="Ihre Telefonnummer"
                tabIndex="3"
              />
            </div>
          <div>
            <label for="message">Ihre Anfrage*</label>
            <textarea
              placeholder="Ihre Nachricht..."
              className="message"
              name="message"
            />
          </div>
          <div className="agreement-wrapper">
            <input type="checkbox" required id="agreement" name="agreement" />
            <label for="agreement">Ich bin damit einverstanden, dass Bellamya die von mir angegebenen personenbezogenen Daten (z.B. Kontaktdetails und persönliche Daten) zur Kundenbetreuung und zur Kontaktaufnahme verarbeitet und nutzt. Ich kann meine gegenüber Bellamya abgegebene Einwilligungserklärungen jederzeit mit Wirkung für die Zukunft über info@bellamya.de widerrufen. Über die genannte Kontaktadresse kann ich ferner jederzeit Auskunft über meine bei netvico gespeicherten Daten sowie die Berichtigung, Löschung oder Sperrung meiner personenbezogenen Angaben verlangen. Weitere Details zur Verarbeitung meiner personenbezogenen Daten durch Bellamya finde ich in der Datenschutzerklärung</label>
          </div>
          <button type="submit" className="send">Anfrage senden</button>
        </form>
        }
        </section>
        <footer>
          <Link to="/privacy">Datenschutzerklärung</Link>
          <Link to="/impressum">Impressum</Link>
        </footer>
      </div>
    );
}

export default App;
